<template>
  <b-modal
    v-model="popup_quiz"
    centered
    hide-footer
    title=""
  >
    <div
      v-if="checkOtherQuestions.length > 0"
      class="mt-1"
    >
      <h5 class="">
        Please answer the following question(s)
      </h5>
    </div>
    <hr v-if="checkOtherQuestions.length > 0">
    <div
      v-for="(query, index) in popup_questions"
      :key="index"
      class="mt-1"
    >
      <b-form-group
        v-if="!query.question.toLowerCase().includes('nric')"
        class="w-100"
        :label="query.question"
        :label-for="query.question"
      >
        <b-form-input
          :id="query.question"
          v-model="query.answer"
        />
      </b-form-group>
      <div
        v-else
        class="w-100 mt-2"
      >
        <hr>
        <h6 class="mt-1">Other information required by the Organiser/Employer</h6>
        <hr>
        <div
          cols="12"
          class="mt-2 w-100"
        >
          <b-form-group
            class="w-100"
            :label="query.question"
            :label-for="query.question"
          >
            <b-form-input
              :id="query.question"
              v-model="query.answer"
              :state="nric_state"
              @update="checkChange($event)"
            />
            <b-form-invalid-feedback>
              A valid NRIC number is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-checkbox
          v-model="save_nric"
          :disabled="!nric_state"
          class="mt-1"
        >Save NRIC number for future applications.</b-form-checkbox>

        <hr>

        <div class="d-flex justify-content-between my-2">
          <b-form-radio
            v-model="resume_choice"
            name="existing-resume"
            value="existing-resume"
            class="mr-1"
          >
            Use Existing Resume
          </b-form-radio>

          <b-form-radio
            v-model="resume_choice"
            name="new-resume"
            value="new-resume"
            class="ml-1"
          >
            Upload A New Resume
          </b-form-radio>
        </div>
        <div
          v-if="resume_choice == 'new-resume'"
          class="mx-auto mt-1"
        >
          <b-form-file
            v-model="resume_file"
            size="lg"
            :state="Boolean(resume_file)"
            accept=".pdf, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </div>
      </div>
    </div>
    <div class="d-flex my-2 w-100">
      <b-button
        block
        :disabled="!checkIfAnswered()"
        class="mx-auto"
        variant="primary"
        @click="joinEvent(popup_event, $event, true)"
      >Apply Now</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    popupQuiz: { type: Boolean, default: false },
  },
  data() {
    return {
      resume_file: null,
      resume_choice: null,
      save_nric: false,
      popup_quiz: false,
      popup_event: null,
      appliedJob: null,
      nric_state: false,
    }
  },
  computed: {
    checkOtherQuestions() {
      return this.popup_questions.filter(
        item => !item.question.toLowerCase().includes('nric'),
      )
    },
  },
  watch: {

  },

  created() {
    this.appliedJob = localStorage.getItem('applied_job')
    this.appliedJob = this.appliedJob ? JSON.parse(localStorage.getItem('applied_job')) : null

    if (this.appliedJob) {
      this.joinEvent(this.appliedJob)
      this.popup_quiz = true
    } else {
      this.$router.push('/')
      
    }
  },
  methods: {
    checkChange(nric) {
      this.nric_state = this.validateNRIC(nric)
    },
    checkIfAnswered() {
      return this.popup_questions.map(quiz => {
        if (quiz.answer === '') {
          return false
        }
        if (
          quiz.question.toLowerCase().includes('nric')
          && !this.validateNRIC(quiz.answer)
        ) {
          return false
        }
        return true
      })
    },

    validateNRIC(nricNo) {
      const regex = new RegExp(/^[STFGM]\d{7}[A-Z]$/)
      const result = regex.test(nricNo)

      return result
    },

    joinEvent(item, event, bypass = false) {
      if (item.application_url) {
        localStorage.setItem('application_url', item.application_url)
      }
      localStorage.setItem('current_event', item._id)
      this.popup_event = item
      if (item.questions.length > 2 && !bypass) {
        const all_questions = item.questions.split('|')
        this.popup_questions = []

        all_questions.map(quiz => {
          const nricAvailable = this.$store.state.auth.ActiveUser.nric_no
          const nricNo = this.$store.state.auth.ActiveUser.nric_no
          if (nricAvailable && quiz.toLowerCase().includes('nric')) {
            this.popup_questions.push({ question: quiz, answer: nricNo })
            this.nric_state = this.validateNRIC(nricNo)
            this.save_nric = true
          } else {
            this.popup_questions.push({ question: quiz, answer: '' })
          }
        })

        if (this.popup_questions.length > 0) {
          // this.popupQuiz = true
        } else {
          this.$bvModal
            .msgBoxConfirm(
              `You are about to apply for ${item.position} event. Do you want to proceed?`,
              {
                title: 'Confirm Job Application',
                okVariant: 'warning',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
              },
            )
            .then(value => {
              if (value) {
                this.confirmApplication()
              } else {
                this.cancelApplication()
              }
            })
        }
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `You are about to apply for ${item.position} event. Do you want to proceed?`,
            {
              title: 'Confirm Job Application',
              okVariant: 'warning',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.confirmApplication()
            } else {
              this.cancelApplication()
            }
          })
      }
    },

    uploadResume(applicationID, job_id, application_url, email) {
      const reader = new FileReader()
      reader.onload = e => {}
      const fd = new FormData()
      fd.append('application_id', applicationID)
      fd.append('resume_file', this.resume_file)
      fd.append('email', email)
      delete this.$http.defaults.headers.common.Authorization
      this.$toast({
        component: this.$toastContent,
        props: {
          title: 'Resume Upload',
          icon: 'AlertCircleIcon',
          text: 'Your resume file is uploading in the background',
          variant: 'warning',
        },
      })
      this.$http
        .post('/api/attach-resume', fd)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.successfulApplication(
              response,
              job_id,
              application_url,
              email,
            )
          } else {
            this.failedApplication(response, item.application_url)
          }
        })
        .catch(error => {
        })
    },

    confirmApplication(args = null) {
      const currentUser = this.$store.state.auth.ActiveUser
      let { email, fullname } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { email: null, fullname: null }
      const item = this.popup_event

      if (args) {
        if (!fullname) {
          fullname = args.fullname
        }

        email = args.email
      }
      let { company_name } = this.$store.state.auth.ActiveUser || {
        company_name: null,
      }
      if (!company_name) {
        company_name = 'No company'
      }

      const all_data = {
        event_id: item._id,
        email,
        fullname,
        approved: true,
        interviewed: false,
        save_nric: this.save_nric,
        company: company_name,
        questions_answers: this.popup_questions,
        resume_file: currentUser.resume_file,
      }

      this.popup_quiz = false

      if (!email) {
        this.event_id = item._id
      } else {
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success, application_id } = response.data
            if (success) {
              if (this.resume_file) {
                this.uploadResume(
                  application_id,
                  item._id,
                  item.application_url,
                  email,
                )
              } else {
                this.successfulApplication(
                  response,
                  item._id,
                  item.application_url,
                  email,
                )
              }
            } else {
              this.failedApplication(response, item.application_url)
            }
          })
          .catch(error => {
          })
      }
    },
    afterApplying() {
      if (this.appliedJob) {
        localStorage.removeItem('applied_job')
        const profileIncomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
        if (profileIncomplete) {
          this.$router.push('/profile')
          // window.location.reload()
        } else {
          this.$router.push('/events-list')
        }
      }
    },
    successfulApplication(response, job_id, application_url, email) {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert',
            icon: 'AlertCircleIcon',
            text: response.data.message,
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.afterApplying()
    //   if (application_url) {
    //     window.location.href = application_url
    //   }
    },

    failedApplication(response, application_url) {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert Failed',
            icon: 'AlertCircleIcon',
            text: response.data.message,
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.afterApplying()
    },
    cancelApplication() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'Your event application has been cancelled.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.afterApplying()
    },
  },
}
</script>
